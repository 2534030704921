import React from "react"
import disenoCss from "../../css/diseno.module.css"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// la imagen
import Foto from "../../components/images/Foto/foto"
import MenuDiseno from "../../components/menuDiseno/menuDiseno"
import LogoBlanco from "../../images/logo-blanco.svg"
import { Link } from "gatsby"

const Fotografia = () => (
  <>
    <div className={disenoCss.background}>
      <div className={disenoCss.gradient}></div>
    </div>
    <Foto></Foto>
    <Link to="/" className={disenoCss.logoBlanco}>
      <img src={LogoBlanco} alt="Logotipo de engrane version blanca" />
    </Link>
    <MenuDiseno></MenuDiseno>
    <div className={disenoCss.container}>
      <Layout siteTitle="Diseño">
        <SEO title="Desarrollo Web" />
        <div className={disenoCss.contenido}>
          <h1 className={disenoCss.titulo}>
            take a <br />
            pick
          </h1>
          <p className={disenoCss.text}>
            Has escuchado ¡Una imagen vale más que mil palabras! Sin duda alguna
            es una gran frase porque si es muy cierto que la fotografía puede
            ser un gran refuerzo para tu marca ya que gracias a esto tus
            clientes te podrán recordar de mejor manera porque no es igual ver
            una fotografía creativa a una fotografía de banco de imágenes.
          </p>
        </div>
      </Layout>
    </div>
    <Link className={disenoCss.productos}>
      PRODUCTOS <span>&nbsp;&nbsp;&nbsp;→</span>
    </Link>
  </>
)

export default Fotografia
